const whitelistedDomain = ['immutable.com', 'localhost'];

export interface ExperimentalOptions {
  runCondition: boolean;
  checkReferrer: boolean;
  message: string;
}

export async function experimental<T>(
  callback: () => Promise<T>,
  options: ExperimentalOptions,
): Promise<T> {
  if (options.runCondition) {
    console.warn(options.message);

    if (options.checkReferrer && !isWhitelisted()) {
      throw new Error('You are not whitelisted for experimental features');
    }
  }

  return callback();
}

const isWhitelisted = (): boolean => {
  return !!whitelistedDomain.find(domain =>
    window.location.origin.includes(domain),
  );
};
