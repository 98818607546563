import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { Web3Provider } from '@ethersproject/providers';
import { ExternalProvider } from '@ethersproject/providers/lib/web3-provider';
import * as E from 'fp-ts/Either';
import { flow, pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { BalanceInfo, ProviderPreference } from '../types';
import { erc20 } from './abi';
import { LocalStorageKeys } from './constants';
import { createExtensionProvider } from './extensionProvider';
import { taskEitherWithError, valueOrThrowTE } from './fp';
import { createMagicProvider } from './magicProvider';
import { ethToken } from './utils';

const getEthereumProvider = ({
  mustBeMetaMask,
  providerPreference,
}: {
  mustBeMetaMask?: boolean;
  providerPreference?: ProviderPreference;
}): Promise<ExternalProvider | null> => {
  if (
    !providerPreference ||
    providerPreference === ProviderPreference.METAMASK
  ) {
    return createExtensionProvider({ mustBeMetaMask });
  }

  if (providerPreference === ProviderPreference.MAGIC_LINK) {
    return createMagicProvider();
  }

  return Promise.reject(new Error('Unknown Ethereum provider'));
};

export const getProvider: TE.TaskEither<Error, Web3Provider> = pipe(
  taskEitherWithError(() => {
    const providerPreference =
      typeof window !== 'undefined'
        ? (window.localStorage?.getItem(
            LocalStorageKeys.PROVIDER_PREFERENCE,
          ) as ProviderPreference)
        : undefined;
    return getEthereumProvider({ mustBeMetaMask: true, providerPreference });
  }),
  TE.chain(
    flow(
      E.fromNullable(
        new Error('Ethereum provider not found, please install MetaMask'),
      ),
      TE.fromEither,
    ),
  ),
  TE.map(provider => new Web3Provider(provider as ExternalProvider)),
);

export const ethBalance = (address: string, interval = 5000) => {
  const balanceObs = new Observable<BalanceInfo>(subscriber => {
    const updateBalance = (provider: Web3Provider): T.Task<void> =>
      subscriber.closed
        ? T.of(undefined)
        : pipe(
            taskEitherWithError(() => provider.getBalance(address)),
            TE.chain(balance =>
              TE.fromIO(() =>
                subscriber.next({ balance, decimal: ethToken.data.decimals }),
              ),
            ),
            TE.fold(
              e => T.fromIO(() => subscriber.error(e)),
              () => pipe(updateBalance(provider), T.delay(interval)),
            ),
          );
    const run = pipe(
      getProvider,
      TE.fold(e => T.fromIO(() => subscriber.error(e)), updateBalance),
    );
    run();
  });

  return pipe(
    balanceObs,
    distinctUntilChanged((prev, curr) => prev.balance.eq(curr.balance)),
  );
};

export const erc20Balance = (
  owner: string,
  tokenAddress: string,
  interval = 5000,
) => {
  const balanceObs = new Observable<BalanceInfo>(subscriber => {
    const updateBalance = (provider: Web3Provider): T.Task<void> =>
      subscriber.closed
        ? T.of(undefined)
        : pipe(
            taskEitherWithError(async () => {
              const erc20Contract = new Contract(tokenAddress, erc20, provider);
              const [balance, decimal] = await Promise.all([
                erc20Contract.balanceOf(owner),
                erc20Contract.decimals(),
              ]);
              return { balance: BigNumber.from(balance), decimal };
            }),
            TE.chain(balanceObj =>
              TE.fromIO(() => subscriber.next(balanceObj)),
            ),
            TE.fold(
              e => T.fromIO(() => subscriber.error(e)),
              () => pipe(updateBalance(provider), T.delay(interval)),
            ),
          );
    const run = pipe(
      getProvider,
      TE.fold(e => T.fromIO(() => subscriber.error(e)), updateBalance),
    );
    run();
  });

  return pipe(
    balanceObs,
    distinctUntilChanged((prev, curr) => prev.balance.eq(curr.balance)),
  );
};
