import { EthNetworkName, Magic } from 'magic-sdk';

import { LocalStorageKeys } from './constants';

interface MagicConfig {
  publishableKey: string;
  network: EthNetworkName;
}

export const getMagicConfig = (): MagicConfig => {
  const storedNetwork =
    typeof window !== 'undefined'
      ? window.localStorage?.getItem(LocalStorageKeys.ETH_NETWORK)
      : 'ropsten';

  return {
    publishableKey:
      storedNetwork === 'mainnet'
        ? 'pk_live_966F443FC2D0DF5D'
        : 'pk_live_A7D9211D7547A338',
    network: storedNetwork as EthNetworkName,
  };
};

export const magicClient = () => {
  const config = getMagicConfig();
  return new Magic(config.publishableKey, {
    network: config.network,
  });
};

export const createMagicProvider = async (magic = magicClient()) =>
  Promise.resolve(magic.rpcProvider);
