/* eslint-disable @typescript-eslint/return-await */
import { Signer } from '@ethersproject/abstract-signer';
import { Contract, PopulatedTransaction } from '@ethersproject/contracts';
import { NonceManager } from '@ethersproject/experimental';
import { formatUnits } from '@ethersproject/units';
import autoBind from 'auto-bind';
import { BigNumber } from 'ethers';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

import { getAssetID, signAuthHeader } from '../crypto';
import { taskEitherWithError, valueOrThrow } from '../libs';
import { erc20 } from '../libs/abi';
import { Token } from '../types';
import { ImmutableXController } from './ImmutableXController';

export type ImmutableXWalletParams = {
  publicApiUrl: string;
  signer: Signer;
  gasLimit?: BigNumber;
  gasPrice?: BigNumber;
  accountMappingKey?: string;
};

/**
 * Immutable X Wallet
 */
export class ImmutableXWallet {
  public controller: ImmutableXController;

  private signer: NonceManager;

  private gasLimit?: BigNumber;

  private gasPrice?: BigNumber;

  constructor({
    publicApiUrl,
    signer,
    gasLimit,
    gasPrice,
    accountMappingKey,
  }: ImmutableXWalletParams) {
    if (!signer.provider) {
      throw new Error('Signer is required to have a provider!');
    }
    this.signer = new NonceManager(signer);
    this.controller = new ImmutableXController(
      publicApiUrl,
      this.signer,
      accountMappingKey,
    );
    this.gasLimit = gasLimit;
    this.gasPrice = gasPrice;
    autoBind(this);
  }

  public static async build(
    params: ImmutableXWalletParams,
  ): Promise<ImmutableXWallet> {
    const wallet = new ImmutableXWallet(params);
    try {
      await wallet.controller.account('starkex', 'immutablex', '1');
    } catch (e) {
      const message = 'Problem connecting starkex account';
      console.log(message, e);
      throw new Error(message);
    }
    return wallet;
  }

  public static buildF(
    params: ImmutableXWalletParams,
  ): TE.TaskEither<Error, ImmutableXWallet> {
    return taskEitherWithError(() => ImmutableXWallet.build(params));
  }

  public incrementNonce(): void {
    if (this.signer instanceof NonceManager)
      this.signer.incrementTransactionCount();
  }

  public getEthKey(
    contractAddress: string,
    starkPublicKey: string,
  ): TE.TaskEither<Error, string> {
    const exchangeContract =
      this.controller.getExchangeContract(contractAddress);
    return taskEitherWithError(() =>
      exchangeContract.getEthKey(starkPublicKey),
    );
  }

  public async getDepositBalance(
    contractAddress: string,
    starkPublicKey: string,
    token: Token,
    vaultId: string,
  ): Promise<string> {
    const exchangeContract =
      this.controller.getExchangeContract(contractAddress);
    const assetId = getAssetID(token).toUint().val;
    return await (
      await exchangeContract.getQuantizedDepositBalance(
        starkPublicKey,
        assetId,
        vaultId,
      )
    ).toString();
  }

  public async getWithdrawalBalance(
    contractAddress: string,
    starkPublicKey: string,
    token: Token,
  ): Promise<string> {
    const exchangeContract =
      this.controller.getExchangeContract(contractAddress);
    const assetId = getAssetID(token).toUint().val;
    return await (
      await exchangeContract.getWithdrawalBalance(starkPublicKey, assetId)
    ).toString();
  }

  public async getBalance(
    owner: string,
    contractAddress: string,
  ): Promise<string> {
    const erc20Contract = new Contract(contractAddress, erc20, this.signer);
    const [balance, decimal] = await Promise.all([
      erc20Contract.balanceOf(owner),
      erc20Contract.decimals(),
    ]);
    return formatUnits(balance, decimal);
  }

  public async getAllowance(
    contractAddress: string,
    owner: string,
    spender: string,
  ): Promise<BigNumber> {
    const erc20Contract = new Contract(contractAddress, erc20, this.signer);
    const allowance = await erc20Contract.allowance(owner, spender);
    return allowance;
  }

  public sendTransactionF(
    unsignedTrx: PopulatedTransaction,
  ): TE.TaskEither<Error, string> {
    return pipe(
      this.gasPrice && this.gasLimit
        ? {
            ...unsignedTrx,
            gasPrice: this.gasPrice.toHexString(),
            gasLimit: this.gasLimit.toHexString(),
          }
        : unsignedTrx,
      trx => taskEitherWithError(() => this.signer.sendTransaction(trx)),
      TE.chain(r => {
        this.incrementNonce();
        return TE.of(r.hash);
      }),
    );
  }

  public async sendTransaction(
    unsignedTrx: PopulatedTransaction,
  ): Promise<string> {
    return valueOrThrow(await this.sendTransactionF(unsignedTrx)());
  }

  public async getAuthenticationHeaders() {
    const timestamp = Math.floor(Date.now() / 1000).toString();
    const signature = await signAuthHeader(timestamp, this.signer);
    return {
      'imx-timestamp': timestamp,
      'imx-signature': signature,
    };
  }
}
