import { formatEther, parseEther } from '@ethersproject/units';
import { AxiosError } from 'axios';
import { BigNumber } from 'ethers';
import { pipe } from 'fp-ts/function';

import {
  ERC20TokenType,
  ETHToken,
  ETHTokenType,
  PositiveBigNumber,
  PositiveNumberString,
  PositiveNumberStringC,
  Token,
} from '../types';
import { valueOrThrow } from './fp';

export function payloadId(): number {
  const datePart: number = new Date().getTime() * Math.pow(10, 3);
  const extraPart: number = Math.floor(Math.random() * Math.pow(10, 3));
  const id: number = datePart + extraPart;
  return id;
}

export function formatError(err: AxiosError): Error {
  if (err.response) {
    if (err.response.status === 429) {
      return new Error('Rated limited');
    }
    return new Error(JSON.stringify(err.response?.data));
  }
  if (err.request) {
    return new Error(`Errno: ${(err as any)?.errno} Errcode: ${err?.code}`);
  }
  return new Error(err?.message);
}

export function sleep(time: number) {
  return new Promise(resolve => {
    setTimeout(resolve, time);
  });
}

export const ethToken: ETHToken = {
  type: ETHTokenType.ETH,
  data: {
    decimals: 18,
  },
};

export const eth8ToWei = (amount: PositiveBigNumber): PositiveBigNumber =>
  pipe(amount.mul(Math.pow(10, 8)), PositiveBigNumber.decode, valueOrThrow);

export const eth8ToEth = (amount: PositiveBigNumber): PositiveNumberString =>
  pipe(
    amount.mul(Math.pow(10, 8)),
    String,
    formatEther,
    PositiveNumberStringC.decode,
    valueOrThrow,
  );

export const weiToEth8 = (amount: PositiveBigNumber): PositiveBigNumber =>
  pipe(amount.div(Math.pow(10, 8)), PositiveBigNumber.decode, valueOrThrow);

export const ethToEth8 = (amount: PositiveNumberString): PositiveBigNumber =>
  pipe(parseEther(amount), PositiveBigNumber.decode, valueOrThrow, weiToEth8);

export const amountToQuantizedAmount = (
  amount: PositiveBigNumber,
  quantum: string,
): PositiveBigNumber => {
  if (quantum === '1') {
    return amount;
  }

  return pipe(
    amount.div(BigNumber.from(quantum)),
    PositiveBigNumber.decode,
    valueOrThrow,
  );
};

/**
 * NOTE: 'quantum' value is required for 'ERC-20' tokens,
 * and should be retreived from the '/tokens' API reponse.
 */
export const tokenQuantizedAmount = (
  token: Token,
  amount: PositiveBigNumber,
  quantum = '1',
): PositiveBigNumber => {
  switch (token.type) {
    case ETHTokenType.ETH: {
      return weiToEth8(amount);
    }

    case ERC20TokenType.ERC20: {
      return amountToQuantizedAmount(amount, quantum);
    }

    default: {
      return amount;
    }
  }
};
